import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class UtilService {

  constructor() { }

  dateToBrFormat(value: string) {
    if ( (value == null) || (value.length === 0)) {
      return '';
    }
    value = value.substr(0, 19);
    value = value.replace(/(\d{4})-(\d{2})-(\d{2})/, '$3/$2/$1');
    return value;
  }

  dateTimeToBrFormat(value: string) {
    if ((value == null) || (value.length === 0)) {
      return '';
    }
    value = value.substr(0, 19);
    value = value.replace(/(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):(\d{2})/, '$3/$2/$1 $4:$5:$6');
    return value;

  }

}
