import { Component, OnInit, Inject } from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { AngularMaterialModule } from 'src/app/angular-material.module';


@Component({
  selector: 'app-confirmdialog',
  templateUrl: './confirmdialog.component.html',
  styleUrls: ['./confirmdialog.component.scss']
})
export class ConfirmDialogComponent {

  color = 'primary';
  mode = 'indeterminate';
  value = 50;
  dialogref: MatDialogRef<ConfirmDialogComponent>;
  // tslint:disable-next-line: max-line-length
  msg = null;
  cancelar = 'Cancelar';
  ok = 'Aceitar';
  enableCancel = true;
  status = false;
  constructor(public dialogRef: MatDialogRef<ConfirmDialogComponent>) {
    this.dialogref = dialogRef;
    this.dialogref.disableClose = true;
  }

  onNoClick(): void {
    this.status = false;
    this.dialogRef.close();
  }
  confirmar(): void {
    this.status = true;
    this.dialogRef.close();
  }

}
