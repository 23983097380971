import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

import { environment } from '../../../environments/environment';
import * as jwt_decode from 'jwt-decode';

export interface Error {
  timestamp: number;
  status: number;
  error: string;
  message: string;
  path: string;
}
export interface USERINFO {
  email: string;
  id: number;
  login: string;
  nome: string;
  perfil: string;
  ultima_atualizacao: string;
}


export interface JWT {
  sub: string;
  exp: number;
  iat: number;
}

const tokenName = 'token';

@Injectable({
  providedIn: 'root',
})
export class AuthService {

  private isLogged$ = new BehaviorSubject(false);
  private url = `${environment.apiBaseUrl}`;
  private user = {password: '', username: '' };
  private userInfo: USERINFO;
  private jwt: JWT | any;

  constructor(private http: HttpClient) {

  }

  public get isLoggedIn(): boolean {
    if (localStorage.getItem('isLoggedin')) {
      return true;
    }
    return false;
    // return this.isLogged$.value;
  }

  public login(data): Observable<any> {
    return this.http.post(`${this.url}/login`, data)
      .pipe(
        map((res: { token: string }) => {
          localStorage.setItem(tokenName, res.token);
          // localStorage.setItem('isLoggedin', 'true');
          return this.user;
        }));
  }

  public me(): Observable<any> {
    return this.http.get(`${this.url}/me`)
      .pipe(
        map((res: USERINFO) => {
          // this.user = res;
          localStorage.setItem('me', JSON.stringify(res));
          // only for example
          // localStorage.setItem('username', res.user.username);
          // localStorage.setItem('email', res.user.email);
          // this.isLogged$.next(true);
          localStorage.setItem('isLoggedin', 'true');
          return this.user;
        }));
  }

  public logout() {
    localStorage.clear();
  }

  public signup(data) {
    return this.http.post(`${this.url}/signup`, data)
      .pipe(
        map((res: { user: any, token: string }) => {
          this.user = res.user;
          localStorage.setItem(tokenName, res.token);
          // only for example
          localStorage.setItem('username', res.user.username);
          localStorage.setItem('email', res.user.email);
          this.isLogged$.next(true);
          return this.user;
        }));
  }

  public get authToken(): string {
    return localStorage.getItem(tokenName);
  }

  public get tokenExpirado(): boolean {
    this.jwt = jwt_decode(this.authToken);
    if (this.jwt.exp > 0) {
      console.log( new Date() > new Date(this.jwt.exp * 1000));
      return new Date() > new Date(this.jwt.exp * 1000);
    }
    return true;
  }

  public get userData(): Observable<any> {
    // send current user or load data from backend using token
    return this.loadUser();
  }

  private loadUser(): Observable<any> {
    // use request to load user data with token
    // it's fake and useing only for example
    if (localStorage.getItem('username') && localStorage.getItem('email')) {
      this.user = {
        username: localStorage.getItem('username'),
        password: localStorage.getItem('email'),
      };
    }
    return of(this.user);
  }
}
