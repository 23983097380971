// tslint:disable: max-line-length
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

import { environment } from 'src/environments/environment';

import {
    EnderecoInterface,
    CepDTOInterface,
    CpfDTOInterface,
    CnpjDTOInterface,
    CpfInterface,
    CnpjInterface,
    BoaEnergiaInterface,
    ApiPages,
    ApiPaginate,
    listaIdsDTOInterface,
    AgrupamentosInterface,
    CidadesInterface,
    EnunsInterface,
    ListasTextInterface
} from '../../interfaces';
import { RamalInterface } from 'src/app/interfaces/ramalinterface';
import { UserInterface } from 'src/app/interfaces/userinterface';
import { LogsInterface } from 'src/app/interfaces/LogsInterface';
import { BuscasInterface } from 'src/app/interfaces/buscasinterface';



export interface Error {
    timestamp: number;
    status: number;
    error: string;
    message: string;
    path: string;
}

@Injectable({
    providedIn: 'root',
})
export class ApiService {


    private url = `${environment.apiBaseUrl}`;

    constructor(private http: HttpClient) {

    }

    public findCep(cep: string): Observable<EnderecoInterface> {
        cep = cep.replace(/[^\d]+/g, '');
        return this.http.get(`${this.url}/cep/${cep}`)
            .pipe(
                map((res: EnderecoInterface) => {
                    return res;
                }));
    }

    public findCpf(cpf: string): Observable<CpfInterface> {
        cpf = cpf.replace(/[^\d]+/g, '');
        return this.http.get(`${this.url}/cpf/${cpf}`)
            .pipe(
                map((res: CpfInterface) => {
                    return res;
                }));
    }

    public findCnpj(cnpj: string): Observable<CnpjInterface> {
        cnpj = cnpj.replace(/[^\d]+/g, '');
        return this.http.get(`${this.url}/cnpj/${cnpj}`)
            .pipe(
                map((res: CnpjInterface) => {
                    return res;
                }));
    }

    public salvarBoaEnergia(data: BoaEnergiaInterface): Observable<any> {
        return this.http.post(`${this.url}/boaenergia`, data)
            .pipe(
                map((res) => {
                    return res;
                }));
    }
    public putBoaEnergia(data: BoaEnergiaInterface): Observable<any> {
        return this.http.put(`${this.url}/boaenergia/${data.id}`, data)
            .pipe(
                map((res) => {
                    return res;
                }));
    }
    public getBoaEnergiaById(id: number): Observable<any> {
        return this.http.get(`${this.url}/boaenergia/${id}`)
            .pipe(
                map((res) => {
                    return res;
                }));
    }
    public deleteBoaEnergiaById(id: number): Observable<any> {
        return this.http.delete(`${this.url}/boaenergia/${id}`)
            .pipe(
                map((res) => {
                    return res;
                }));
    }

    public deleteUsuarioById(id: number): Observable<any> {
        return this.http.delete(`${this.url}/usuario/${id}`)
            .pipe(
                map((res) => {
                    return res;
                }));
    }

    public salvarUsuario(data: UserInterface): Observable<any> {
        return this.http.post(`${this.url}/usuario`, data)
            .pipe(
                map((res) => {
                    return res;
                }));
    }
    public putUsuario(data: UserInterface): Observable<any> {
        return this.http.put(`${this.url}/usuario/${data.id}`, data)
            .pipe(
                map((res) => {
                    return res;
                }));
    }

    // tslint:disable-next-line: max-line-length
    public getPageUsuarios(query: ApiPaginate = { page: 0, linesPerPage: 100, orderBy: 'id', direction: 'ASC', search: '' }): Observable<ApiPages> {
        const params = new HttpParams()
            .set('page', query.page.toString())
            .set('linesPerPage', query.linesPerPage.toString())
            .set('orderBy', query.orderBy.toString())
            .set('direction', query.direction.toString())
            .set('search', ((query.search === undefined) ? '' : query.search.toString()));

        return this.http.get(`${this.url}/usuario/page`, { params })
            .pipe(
                map((res: ApiPages) => {
                    return res;
                }));
    }

    public getPageBoaEnergiaDashboard(): Observable<ApiPages> {
        const params = new HttpParams()
            .set('utltimodia', 'true');
        return this.http.get(`${this.url}/boaenergia/page`, { params })
            .pipe(
                map((res: ApiPages) => {
                    return res;
                }));
    }

    public getPageRamalDashboard(): Observable<ApiPages> {
        const params = new HttpParams()
        .set('utltimodia', 'true');
        return this.http.get(`${this.url}/ramal/page`, { params })
            .pipe(
                map((res: ApiPages) => {
                    return res;
                }));
    }

    public getPageBoaEnergia(query: ApiPaginate = { page: 0, linesPerPage: 100, orderBy: 'id', direction: 'ASC', search: '' }): Observable<ApiPages> {
        const params = new HttpParams()
            .set('page', query.page.toString())
            .set('linesPerPage', query.linesPerPage.toString())
            .set('orderBy', query.orderBy.toString())
            .set('direction', query.direction.toString())
            .set('search', ((query.search === undefined) ? '' : query.search.toString()))
            .set('idcadastroboaenergia', ((query.idcadastroboaenergia === undefined) ? '' : query.idcadastroboaenergia))
            .set('cpfcnpj', ((query.cpfcnpj === undefined) ? '' : query.cpfcnpj))
            .set('data_inicio', ((query.data_inicio === undefined) ? '' : query.data_inicio))
            .set('data_final', ((query.data_final === undefined) ? '' : query.data_final));

        return this.http.get(`${this.url}/boaenergia/page`, { params })
            .pipe(
                map((res: ApiPages) => {
                    return res;
                }));
    }

    public getPageBoaEnergiaModulo(query: ApiPaginate = { page: 0, linesPerPage: 100, orderBy: 'id', direction: 'ASC', search: '' }): Observable<ApiPages> {
        const params = new HttpParams()
            .set('page', query.page.toString())
            .set('linesPerPage', query.linesPerPage.toString())
            .set('orderBy', query.orderBy.toString())
            .set('direction', query.direction.toString())
            .set('search', ((query.search === undefined) ? '' : query.search.toString()))
            .set('idcadastroboaenergia', ((query.idcadastroboaenergia === undefined) ? '' : query.idcadastroboaenergia))
            .set('cpfcnpj', ((query.cpfcnpj === undefined) ? '' : query.cpfcnpj))
            .set('data_inicio', ((query.data_inicio === undefined) ? '' : query.data_inicio))
            .set('data_final', ((query.data_final === undefined) ? '' : query.data_final));

        return this.http.get(`${this.url}/boaenergia/modulo/page`, { params })
            .pipe(
                map((res: ApiPages) => {
                    return res;
                }));
    }
    public getPageRamal(query: ApiPaginate = { page: 0, linesPerPage: 100, orderBy: 'id', direction: 'ASC', search: '' }): Observable<ApiPages> {
        const params = new HttpParams()
            .set('page', query.page.toString())
            .set('linesPerPage', query.linesPerPage.toString())
            .set('orderBy', query.orderBy.toString())
            .set('direction', query.direction.toString())
            .set('search', ((query.search === undefined) ? '' : query.search.toString()))
            .set('data_inicio', ((query.data_inicio === undefined) ? '' : query.data_inicio))
            .set('data_final', ((query.data_final === undefined) ? '' : query.data_final))
            .set('agrupamento_id', ((query.agrupamento_id === undefined) ? '0' : query.agrupamento_id.toString()))
            .set('trafo', ((query.trafo === undefined) ? '' : query.trafo))


        return this.http.get(`${this.url}/ramal/page`, { params })
            .pipe(
                map((res: ApiPages) => {
                    return res;
                }));
    }


    public downloadXlsxBoaEnergia(data: listaIdsDTOInterface): Observable<any> {
        const options = {
            headers: new HttpHeaders().append('Accept', 'application/octet-stream'),
            responseType: 'blob'
        };
        // tslint:disable-next-line: max-line-length
        return this.http.post(`${this.url}/boaenergia/xlsx`, data, { headers: new HttpHeaders().append('Accept', 'application/octet-stream'), responseType: 'blob' }).pipe(
            map((report) => {
                console.log(report);
                return report;
            }));
    }


    public downloadImagensBoaEnergia(data: listaIdsDTOInterface): Observable<any> {
        const options = {
            headers: new HttpHeaders().append('Accept', 'application/octet-stream'),
            responseType: 'blob'
        };
        // tslint:disable-next-line: max-line-length
        return this.http.post(`${this.url}/boaenergia/imagens`, data, { headers: new HttpHeaders().append('Accept', 'application/octet-stream'), responseType: 'blob' }).pipe(
            map((report) => {
                console.log(report);
                return report;
            }));
    }

    public downloadXlsxBoaEnergiaModulo(data: listaIdsDTOInterface): Observable<any> {
        const options = {
            headers: new HttpHeaders().append('Accept', 'application/octet-stream'),
            responseType: 'blob'
        };
        // tslint:disable-next-line: max-line-length
        return this.http.post(`${this.url}/boaenergia/modulo/xlsx`, data, { headers: new HttpHeaders().append('Accept', 'application/octet-stream'), responseType: 'blob' }).pipe(
            map((report) => {
                console.log(report);
                return report;
            }));
    }

    public downloadXlsxBoaEnergiaAll(query: ApiPaginate = { page: 0, linesPerPage: 100, orderBy: 'id', direction: 'ASC', search: '' }): Observable<any> {
        const params = new HttpParams()
            .set('page', query.page.toString())
            .set('linesPerPage', query.linesPerPage.toString())
            .set('orderBy', query.orderBy.toString())
            .set('direction', query.direction.toString())
            .set('search', ((query.search === undefined) ? '' : query.search.toString()))
            .set('idcadastroboaenergia', ((query.idcadastroboaenergia === undefined) ? '' : query.idcadastroboaenergia))
            .set('cpfcnpj', ((query.cpfcnpj === undefined) ? '' : query.cpfcnpj))
            .set('data_inicio', ((query.data_inicio === undefined) ? '' : query.data_inicio))
            .set('data_final', ((query.data_final === undefined) ? '' : query.data_final));
        const options = {
            headers: new HttpHeaders().append('Accept', 'application/octet-stream'),
            responseType: 'blob'
        };
        // tslint:disable-next-line: max-line-length
        return this.http.post(`${this.url}/boaenergia/xlsx/all`, params, { headers: new HttpHeaders().append('Accept', 'application/octet-stream'), responseType: 'blob' }).pipe(
            map((report) => {
                console.log(report);
                return report;
            }));
    }

    public downloadImagensBoaEnergiaById(id): Observable<any> {
        const options = {
            headers: new HttpHeaders().append('Accept', 'application/octet-stream'),
            responseType: 'blob'
        };
        // tslint:disable-next-line: max-line-length
        return this.http.get(`${this.url}/boaenergia/imagens/${id}`, { headers: new HttpHeaders().append('Accept', 'application/octet-stream'), responseType: 'blob' }).pipe(
            map((report) => {
                console.log(report);
                return report;
            }));
    }

    public downloadImagensBoaEnergiaAll(query: ApiPaginate = { page: 0, linesPerPage: 100, orderBy: 'id', direction: 'ASC', search: '' }): Observable<any> {
        const params = new HttpParams()
            .set('page', query.page.toString())
            .set('linesPerPage', query.linesPerPage.toString())
            .set('orderBy', query.orderBy.toString())
            .set('direction', query.direction.toString())
            .set('search', ((query.search === undefined) ? '' : query.search.toString()))
            .set('idcadastroboaenergia', ((query.idcadastroboaenergia === undefined) ? '' : query.idcadastroboaenergia))
            .set('cpfcnpj', ((query.cpfcnpj === undefined) ? '' : query.cpfcnpj))
            .set('data_inicio', ((query.data_inicio === undefined) ? '' : query.data_inicio))
            .set('data_final', ((query.data_final === undefined) ? '' : query.data_final));
        const options = {
            headers: new HttpHeaders().append('Accept', 'application/octet-stream'),
            responseType: 'blob'
        };
        // tslint:disable-next-line: max-line-length
        return this.http.post(`${this.url}/boaenergia/imagens/all`, params, { headers: new HttpHeaders().append('Accept', 'application/octet-stream'), responseType: 'blob' }).pipe(
            map((report) => {
                console.log(report);
                return report;
            }));
    }


    public downloadXlsxBoaEnergiaAllModulo(query: ApiPaginate = { page: 0, linesPerPage: 100, orderBy: 'id', direction: 'ASC', search: '' }): Observable<any> {
        const params = new HttpParams()
            .set('page', query.page.toString())
            .set('linesPerPage', query.linesPerPage.toString())
            .set('orderBy', query.orderBy.toString())
            .set('direction', query.direction.toString())
            .set('search', ((query.search === undefined) ? '' : query.search.toString()))
            .set('idcadastroboaenergia', ((query.idcadastroboaenergia === undefined) ? '' : query.idcadastroboaenergia))
            .set('cpfcnpj', ((query.cpfcnpj === undefined) ? '' : query.cpfcnpj))
            .set('data_inicio', ((query.data_inicio === undefined) ? '' : query.data_inicio))
            .set('data_final', ((query.data_final === undefined) ? '' : query.data_final));
        const options = {
            headers: new HttpHeaders().append('Accept', 'application/octet-stream'),
            responseType: 'blob'
        };
        // tslint:disable-next-line: max-line-length
        return this.http.post(`${this.url}/boaenergia/modulo/xlsx/all`, params, { headers: new HttpHeaders().append('Accept', 'application/octet-stream'), responseType: 'blob' }).pipe(
            map((report) => {
                console.log(report);
                return report;
            }));
    }

    // tslint:disable-next-line: max-line-length
    public getAgrupamentos(query: ApiPaginate = { page: 0, linesPerPage: 100, orderBy: 'id', direction: 'ASC', search: '' }): Observable<ApiPages> {
        const params = new HttpParams()
            .set('page', query.page.toString())
            .set('linesPerPage', query.linesPerPage.toString())
            .set('orderBy', query.orderBy.toString())
            .set('direction', query.direction.toString())
            .set('search', ((query.search === undefined) ? '' : query.search.toString()))
            .set('data_inicio', ((query.data_inicio === undefined) ? '' : query.data_inicio))
            .set('data_final', ((query.data_final === undefined) ? '' : query.data_final))
            .set('cidade_id', ((query.cidade_id === undefined || query.cidade_id == null) ? '' : query.cidade_id.toString()))
            ;

        return this.http.get(`${this.url}/agrupamentos/page`, { params })
            .pipe(
                map((res: ApiPages) => {
                    return res;
                }));
    }
    public getAgrupamentosById(id:number): Observable<any> {
        return this.http.get(`${this.url}/agrupamentos/${id}`)
            .pipe(
                map((res: ApiPages) => {
                    return res;
                }));
    }

    public getUsuarioById(id:number): Observable<any> {
        return this.http.get(`${this.url}/usuario/${id}`)
            .pipe(
                map((res: ApiPages) => {
                    return res;
                }));
    }


    // tslint:disable-next-line: max-line-length
    public getAgrupamentosObservable(query: ApiPaginate = { page: 0, linesPerPage: 100, orderBy: 'id', direction: 'ASC', search: '' }): Observable<AgrupamentosInterface[]> {
        const params = new HttpParams()
            .set('page', query.page.toString())
            .set('linesPerPage', query.linesPerPage.toString())
            .set('orderBy', query.orderBy.toString())
            .set('direction', query.direction.toString())
            .set('search', ((query.search === undefined) ? '' : query.search.toString()));
        console.log(params);
        return this.http.get(`${this.url}/agrupamentos/page`, { params }).pipe(
            map((res: ApiPages) => {
                let content: AgrupamentosInterface[] = [];
                content = res.content;
                return content;
            }));
    }

    public getListAgrupamentos(): Observable<AgrupamentosInterface[]> {
        return this.http.get(`${this.url}/agrupamentos/all`, {}).pipe(
            map((res: AgrupamentosInterface[]) => {
                return res;
            }));
    }

    public getListTrafos(): Observable<ListasTextInterface[]> {
        return this.http.get(`${this.url}/ramal/trafos`, {}).pipe(
            map((res: []) => {
                let res2 = res.filter(function (el) {
                    return el != null;
                });
                console.log("RES v")
                console.log(res2)
                console.log("RES ^")

                return res2.map((valor) => {
                    return {
                        value: valor,
                        viewValue: valor
                    };
                });
            }));
    }

    public getCidadesObservable(query: ApiPaginate = { page: 0, linesPerPage: 100, orderBy: 'id', direction: 'ASC', search: '' }): Observable<CidadesInterface[]> {
        const params = new HttpParams()
            .set('page', query.page.toString())
            .set('linesPerPage', query.linesPerPage.toString())
            .set('orderBy', query.orderBy.toString())
            .set('direction', query.direction.toString())
            .set('search', ((query.search === undefined) ? '' : query.search.toString()));
        console.log(params);
        return this.http.get(`${this.url}/estados/8/cidades`, { params }).pipe(
            map((res: CidadesInterface[]) => {
                return res;
            }));
    }

    public getTipoLigacaoObservable(): Observable<EnunsInterface[]> {
        const params = new HttpParams();
        return this.http.get(`${this.url}/selects/tipoligacao`, { params }).pipe(
            map((res: EnunsInterface[]) => {
                return res;
            }));
    }

    public getTipoDeRedeObservable(): Observable<EnunsInterface[]> {
        const params = new HttpParams();
        console.log(params);
        return this.http.get(`${this.url}/selects/tiporede`, { params }).pipe(
            map((res: EnunsInterface[]) => {
                return res;
            }));
    }

    public getFaseamentoObservable(): Observable<EnunsInterface[]> {
        const params = new HttpParams();
        console.log(params);
        return this.http.get(`${this.url}/selects/faseamento`, { params }).pipe(
            map((res: EnunsInterface[]) => {
                return res;
            }));
    }



    public postRamal(data: RamalInterface): Observable<any> {
        return this.http.post(`${this.url}/ramal`, data)
            .pipe(
                map((res) => {
                    return res;
                }));
    }
    public getRamalById(id: number): Observable<any> {
        return this.http.get(`${this.url}/ramal/${id}`)
            .pipe(
                map((res) => {
                    return res;
                }));
    }
    public putRamal(ramal: RamalInterface): Observable<any> {
        return this.http.put(`${this.url}/ramal/${ramal.id}`, ramal)
            .pipe(
                map((res) => {
                    return res;
                }));
    }
    public downloadXlsxRamal(data: listaIdsDTOInterface): Observable<any> {
        const options = {
            headers: new HttpHeaders().append('Accept', 'application/octet-stream'),
            responseType: 'blob'
        };
        // tslint:disable-next-line: max-line-length
        return this.http.post(`${this.url}/ramal/xlsx`, data, { headers: new HttpHeaders().append('Accept', 'application/octet-stream'), responseType: 'blob' }).pipe(
            map((report) => {
                console.log(report);
                return report;
            }));
    }
    public downloadXlsxRamalAll(query: ApiPaginate = { page: 0, linesPerPage: 100, orderBy: 'id', direction: 'ASC', search: '' }): Observable<any> {
        const params = new HttpParams()
            .set('page', query.page.toString())
            .set('linesPerPage', query.linesPerPage.toString())
            .set('orderBy', query.orderBy.toString())
            .set('direction', query.direction.toString())
            .set('search', ((query.search === undefined) ? '' : query.search.toString()))
            .set('data_inicio', ((query.data_inicio === undefined) ? '' : query.data_inicio))
            .set('data_final', ((query.data_final === undefined) ? '' : query.data_final))
            .set('agrupamento_id', ((query.agrupamento_id === undefined) ? '0' : query.agrupamento_id.toString()))
            .set('trafo', ((query.trafo === undefined) ? '' : query.trafo))
        const options = {
            headers: new HttpHeaders().append('Accept', 'application/octet-stream'),
            responseType: 'blob'
        };
        // tslint:disable-next-line: max-line-length
        return this.http.post(`${this.url}/ramal/xlsx/all`, params, { headers: new HttpHeaders().append('Accept', 'application/octet-stream'), responseType: 'blob' }).pipe(
            map((report) => {
                console.log(report);
                return report;
            }));
    }



    public downloadXlsxMaterial(data: listaIdsDTOInterface): Observable<any> {
        const options = {
            headers: new HttpHeaders().append('Accept', 'application/octet-stream'),
            responseType: 'blob'
        };
        // tslint:disable-next-line: max-line-length
        return this.http.post(`${this.url}/ramal/xlsx/materiais`, data, { headers: new HttpHeaders().append('Accept', 'application/octet-stream'), responseType: 'blob' }).pipe(
            map((report) => {
                console.log(report);
                return report;
            }));
    }
    public downloadXlsxMaterialAll(query: ApiPaginate = { page: 0, linesPerPage: 100, orderBy: 'id', direction: 'ASC', search: '' }): Observable<any> {
        const params = new HttpParams()
            .set('page', query.page.toString())
            .set('linesPerPage', query.linesPerPage.toString())
            .set('orderBy', query.orderBy.toString())
            .set('direction', query.direction.toString())
            .set('search', ((query.search === undefined) ? '' : query.search.toString()))
            .set('data_inicio', ((query.data_inicio === undefined) ? '' : query.data_inicio))
            .set('data_final', ((query.data_final === undefined) ? '' : query.data_final))
            .set('agrupamento_id', ((query.agrupamento_id === undefined) ? '0' : query.agrupamento_id.toString()))
            .set('trafo', ((query.trafo === undefined) ? '' : query.trafo))
        const options = {
            headers: new HttpHeaders().append('Accept', 'application/octet-stream'),
            responseType: 'blob'
        };
        // tslint:disable-next-line: max-line-length
        return this.http.post(`${this.url}/ramal/xlsx/materiais/all`, params, { headers: new HttpHeaders().append('Accept', 'application/octet-stream'), responseType: 'blob' }).pipe(
            map((report) => {
                console.log(report);
                return report;
            }));
    }

    public downloadXlsxUsuario(data: listaIdsDTOInterface): Observable<any> {
        const options = {
            headers: new HttpHeaders().append('Accept', 'application/octet-stream'),
            responseType: 'blob'
        };
        // tslint:disable-next-line: max-line-length
        return this.http.post(`${this.url}/usuario/xlsx`, data, { headers: new HttpHeaders().append('Accept', 'application/octet-stream'), responseType: 'blob' }).pipe(
            map((report) => {
                console.log(report);
                return report;
            }));
    }

    public downloadXlsxAgrupamentos(data: listaIdsDTOInterface): Observable<any> {
        const options = {
            headers: new HttpHeaders().append('Accept', 'application/octet-stream'),
            responseType: 'blob'
        };
        // tslint:disable-next-line: max-line-length
        return this.http.post(`${this.url}/agrupamentos/xlsx`, data, { headers: new HttpHeaders().append('Accept', 'application/octet-stream'), responseType: 'blob' }).pipe(
            map((report) => {
                console.log(report);
                return report;
            }));
    }
    public downloadXlsxLogs(data: listaIdsDTOInterface): Observable<any> {
        const options = {
            headers: new HttpHeaders().append('Accept', 'application/octet-stream'),
            responseType: 'blob'
        };
        // tslint:disable-next-line: max-line-length
        return this.http.post(`${this.url}/logs/xlsx`, data, { headers: new HttpHeaders().append('Accept', 'application/octet-stream'), responseType: 'blob' }).pipe(
            map((report) => {
                console.log(report);
                return report;
            }));
    }
    public downloadXlsxAgrupamentosAll(query: ApiPaginate = { page: 0, linesPerPage: 100, orderBy: 'id', direction: 'ASC', search: '' }): Observable<any> {
        const params = new HttpParams()
            .set('page', query.page.toString())
            .set('linesPerPage', query.linesPerPage.toString())
            .set('orderBy', query.orderBy.toString())
            .set('direction', query.direction.toString())
            .set('search', ((query.search === undefined) ? '' : query.search.toString()))
            .set('cidade_id', ((query.cidade_id === undefined || query.cidade_id == null) ? '' : query.cidade_id.toString()))
            .set('data_inicio', ((query.data_inicio === undefined) ? '' : query.data_inicio))
            .set('data_final', ((query.data_final === undefined) ? '' : query.data_final));
        console.log(params);

        const options = {
            headers: new HttpHeaders().append('Accept', 'application/octet-stream'),
            responseType: 'blob'
        };
        // tslint:disable-next-line: max-line-length
        return this.http.post(`${this.url}/agrupamentos/xlsx/all`, params, { headers: new HttpHeaders().append('Accept', 'application/octet-stream'), responseType: 'blob' }).pipe(
            map((report) => {
                console.log(report);
                return report;
            }));
    }

    public downloadXlsxLogsAll(query: ApiPaginate = { page: 0, linesPerPage: 100, orderBy: 'id', direction: 'ASC', search: '' }): Observable<any> {
        const params = new HttpParams()
            .set('page', query.page.toString())
            .set('linesPerPage', query.linesPerPage.toString())
            .set('orderBy', query.orderBy.toString())
            .set('direction', query.direction.toString())
            .set('search', ((query.search === undefined) ? '' : query.search.toString()))
            .set('data_inicio', ((query.data_inicio === undefined) ? '' : query.data_inicio))
            .set('data_final', ((query.data_final === undefined) ? '' : query.data_final));
        console.log(params);

        const options = {
            headers: new HttpHeaders().append('Accept', 'application/octet-stream'),
            responseType: 'blob'
        };
        // tslint:disable-next-line: max-line-length
        return this.http.post(`${this.url}/logs/xlsx/all`, params, { headers: new HttpHeaders().append('Accept', 'application/octet-stream'), responseType: 'blob' }).pipe(
            map((report) => {
                console.log(report);
                return report;
            }));
    }
    public salvarAgrupamento(data: AgrupamentosInterface): Observable<any> {
        return this.http.post(`${this.url}/agrupamentos`, data)
            .pipe(
                map((res) => {
                    return res;
                }));
    }
    public putAgrupamento(data: AgrupamentosInterface): Observable<any> {
        return this.http.put(`${this.url}/agrupamentos/${data.id}`, data)
            .pipe(
                map((res) => {
                    return res;
                }));
    }

    public getListLogs(query: ApiPaginate = { page: 0, linesPerPage: 100, orderBy: 'id', direction: 'ASC', search: '' }): Observable<ApiPages> {
        const params = new HttpParams()
            .set('page', query.page.toString())
            .set('linesPerPage', query.linesPerPage.toString())
            .set('orderBy', query.orderBy.toString())
            .set('direction', query.direction.toString())
            .set('search', ((query.search === undefined) ? '' : query.search.toString()))
            .set('data_inicio', ((query.data_inicio === undefined) ? '' : query.data_inicio.toString()))
            .set('data_final', ((query.data_final === undefined) ? '' : query.data_final.toString()))
            ;

        return this.http.get(`${this.url}/logs/page`, { params })
            .pipe(
                map((res: ApiPages) => {
                    return res;
                }));
    }
    public uploadboaenergia(form : FormData): Observable<BoaEnergiaInterface[]> {
        return this.http.post(`${this.url}/boaenergia/addxlsx`, form)
            .pipe(
                map((res : BoaEnergiaInterface[]) => {
                    return res;
                }));
    }

}



