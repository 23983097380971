import { Component, NgModule, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material';
import { CsModuloInterface } from 'src/app/interfaces/csmodulointerface';
import { Observable } from 'rxjs';
import { BoaEnergiaInterface, EnunsInterface, ListasInterface } from 'src/app/interfaces';
import { SelectsService } from 'src/app/services/selects/selects.service';
import { CsInterface } from 'src/app/interfaces/csinterface';
import { DomSanitizer } from '@angular/platform-browser';
import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { LoadingComponent } from '../loading/loading.component';
import { ApiService } from 'src/app/services';




@Component({
    selector: 'app-imagensboa',
    templateUrl: './imagensboa.component.html',
    styleUrls: ['./imagensboa.component.scss']
})
export class ImagensboaComponent {

    dialogref: MatDialogRef<ImagensboaComponent>;
    cancelar = false;
    imagens : BoaEnergiaInterface;
    domSanitizer: DomSanitizer;
    dialogRef2: MatDialogRef<LoadingComponent>;

    constructor(
        public dialogRef: MatDialogRef<ImagensboaComponent>,
        private selects: SelectsService,
        private sanitizer: DomSanitizer,
        public dialog: MatDialog,
        private api: ApiService) {
        this.dialogref = dialogRef;
        this.dialogref.disableClose = true;
        this.domSanitizer = sanitizer;

    }




    ngOnInit() {

    }

    setImages(): void {

    }

    sair() {
        console.log(this.imagens);
        this.dialogRef.close();
    }

    getImage(value: string) {
        return this.domSanitizer.bypassSecurityTrustUrl('data:image/png;base64,' + value);
    }

    imagenById(id){
        this.openDialog();
        this.api.downloadImagensBoaEnergiaById(id)
        .subscribe(response => {
            this.dialogRef2.close();
            console.log(response)
            const a = document.createElement('a');
            document.body.appendChild(a);
            const blob: any = response;
            const url = window.URL.createObjectURL(blob);
            a.href = url;
            a.download = (new Date()).getTime().toString() + '_report.zip';
            a.click();
            window.URL.revokeObjectURL(url);
        }, error => {
            this.dialogRef2.close();
            console.log('Error downloading the file')
            console.log(error)
        });
        return;
    }

    openDialog(): void {
        this.dialogRef2 = this.dialog.open(LoadingComponent, {
            //height: '200px',
            //width:  '200px',
        });
        this.dialogRef2.afterClosed().subscribe(result => {
            console.log('The dialog was closed');
        });
    }
}
