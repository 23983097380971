import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'telefone'
})
export class TelefonePipe implements PipeTransform {

  transform(value: any, args?: any): any {
    value = value.replace(/[^\d]+/g, '');
    value = String(value).substr(0, 11);
    if (String(value).length === 11) {
      value = String(value).replace(/(\d{2})(\d{1})(\d{4})(\d)/,'($1) $2 $3-$4');
    } else if (String(value).length === 10) {
      value = String(value).replace(/(\d{2})(\d{4})(\d)/,'($1) $2-$3');
    }
    return value;
  }

}
